import { ConfigurationTable } from "../component/table/ConfigurationTable";
import { useEffect, useState } from "react";
import { Configuration } from "../common/types/models";
import { useCognito } from "../auth/cognito";
import { ConsoleRouteConfig } from "../common/routes";
import { CreateConfigurationWizard } from "../component/wizard/CreateConfigurationWizard";
import { createConfiguration, listConfigurations } from "../api/configurations";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Link from "@cloudscape-design/components/link";
import { useParams } from "react-router-dom";
import { ConfigurationEditor } from "../component/views/ConfigurationEditor";

export const ConfigurationView = () => {
  const { id } = useParams<{ id: string }>();
  const [dataLoading, setDataLoading] = useState(false);
  const [configurations, setConfigurations] = useState<Configuration[]>([]);

  const { cognitoLoading, authenticated, accessToken } = useCognito();
  useEffect(() => {
    if (cognitoLoading) return;
    if (!authenticated) {
      window.location.href = `#${ConsoleRouteConfig.auth.href}`;
    }
  }, [cognitoLoading, authenticated]);

  const refreshConfigurations = async () => {
    if (!accessToken) return;
    setDataLoading(true);
    console.log("refreshing configurations");
    const configurations = await listConfigurations(accessToken);
    setConfigurations(configurations);
    setDataLoading(false);
  };

  useEffect(() => {
    refreshConfigurations();
  }, [accessToken]);

  const Overview = () => (
    <SpaceBetween size="xl">
      <ConfigurationTable
        items={configurations}
        loading={dataLoading}
        onRefresh={refreshConfigurations}
        accessToken={accessToken}
        onItemClicked={(item) => {
          window.location.href = `#${ConsoleRouteConfig.configuration.href}/${item.configurationId}`;
        }}
      />
      <CreateConfigurationWizard
        onSubmit={async (request) => {
          try {
            await createConfiguration(accessToken!, request);
          } catch (error) {
            console.error("Failed to create configuration", error);
          } finally {
            refreshConfigurations();
          }
        }}
        onCancel={() => {}}
      />
    </SpaceBetween>
  );

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Configuration
        </Header>
      }
    >
      {id ? <ConfigurationEditor configurationId={id} accessToken={accessToken} /> : <Overview />}
    </ContentLayout>
  );
};
