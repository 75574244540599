import Wizard from "@cloudscape-design/components/wizard";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import { FunctionComponent, useEffect, useState } from "react";
import Link from "@cloudscape-design/components/link";
import Container from "@cloudscape-design/components/container";
import FormField from "@cloudscape-design/components/form-field";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import Input from "@cloudscape-design/components/input";
import Select from "@cloudscape-design/components/select";

import { FluxWidgetProps } from "../../common/standards";
import { createFluxNotification, renderErrorMessage } from "../../common/helpers";
import { listCaseContexts } from "../../api/caseContext";
import { listConfigurations } from "../../api/configurations";
import { CaseContext, Configuration } from "../../common/types/models";
import { SelectProps } from "@cloudscape-design/components/select/interfaces";

export interface CreateReportWizardProps extends FluxWidgetProps {
  onSubmit: () => void;
  onCancel: () => void;
}

export const CreateReportWizard: FunctionComponent<CreateReportWizardProps> = ({
  onSubmit,
  onCancel,
  setNotifications,
  notifications,
  accessToken,
}) => {
  const [reportName, setReportName] = useState("");
  const [reportDescription, setReportDescription] = useState("");

  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [selectedConfiguration, setSelectedConfiguration] = useState<SelectProps.Option | null>(null);
  const [configurationsLoading, setConfigurationsLoading] = useState(false);

  const [caseContexts, setCaseContexts] = useState<CaseContext[]>([]);
  const [selectedCase, setSelectedCase] = useState<SelectProps.Option | null>(null);
  const [casesLoading, setCasesLoading] = useState(false);

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchConfigurations = async () => {
    if (!accessToken) return;
    try {
      setConfigurationsLoading(true);
      const configs = await listConfigurations(accessToken);
      setConfigurations(configs);
    } catch (error: any) {
      createFluxNotification({
        header: "Failed to load configurations",
        content: renderErrorMessage(error),
        type: "error",
        id: "failed_to_load_configurations",
        setNotifications,
        notifications,
      });
    } finally {
      setConfigurationsLoading(false);
    }
  };

  const fetchCases = async () => {
    if (!accessToken) return;
    try {
      setCasesLoading(true);
      const contexts = await listCaseContexts(accessToken);
      setCaseContexts(contexts);
    } catch (error: any) {
      createFluxNotification({
        header: "Failed to load cases",
        content: renderErrorMessage(error),
        type: "error",
        id: "failed_to_load_cases",
        setNotifications,
        notifications,
      });
    } finally {
      setCasesLoading(false);
    }
  };

  useEffect(() => {
    fetchConfigurations();
  }, [accessToken]);

  useEffect(() => {
    fetchCases();
  }, [accessToken]);

  const reset = () => {
    setReportName("");
    setReportDescription("");
    setSelectedConfiguration(null);
    setSelectedCase(null);
    setActiveStepIndex(0);
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!accessToken || !selectedConfiguration || !selectedCase) {
      createFluxNotification({
        header: "Missing required information",
        content: "Please select both a configuration and a case",
        type: "error",
        id: "missing_required_info",
        setNotifications,
        notifications,
      });
      return;
    }

    try {
      setLoading(true);
      // TODO: Implement API call to create report
      // Example:
      // await createReport(accessToken, {
      //   name: reportName,
      //   description: reportDescription,
      //   configurationId: selectedConfiguration.value,
      //   caseContextId: selectedCase.value
      // });

      createFluxNotification({
        header: "Report created successfully",
        content: "Your report has been created successfully",
        type: "success",
        id: "report_create_success",
        setNotifications,
        notifications,
      });
    } catch (error: any) {
      createFluxNotification({
        header: "Failed to create report",
        content: renderErrorMessage(error),
        type: "error",
        id: "failed_to_create_report",
        setNotifications,
        notifications,
      });
    } finally {
      setLoading(false);
      onSubmit();
      reset();
    }
  };

  const configurationOptions = configurations.map((config) => ({
    label: config.name || config.configurationId,
    value: config.configurationId,
    description: config.description || "No description",
  }));

  const caseOptions = caseContexts.map((caseContext) => ({
    label: caseContext.name || caseContext.contextId,
    value: caseContext.contextId,
    description: caseContext.description || "No description",
  }));

  return (
    <Container header={<Header variant="h2">Create New Report</Header>}>
      <Wizard
        onSubmit={handleSubmit}
        onCancel={() => {
          reset();
          onCancel();
        }}
        isLoadingNextStep={loading}
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Create Report",
          optional: "optional",
        }}
        onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
        activeStepIndex={activeStepIndex}
        allowSkipTo
        steps={[
          {
            title: "Basic Information",
            info: <Link variant="info">Info</Link>,
            description: "Provide details for the report",
            content: (
              <Container>
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Report Name">
                    <Input
                      value={reportName}
                      placeholder="My Report Name"
                      onChange={(event) => setReportName(event.detail.value)}
                    />
                  </FormField>
                  <FormField label="Report Description">
                    <Input
                      value={reportDescription}
                      placeholder="Simple description"
                      onChange={(event) => setReportDescription(event.detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Select Configuration",
            info: <Link variant="info">Info</Link>,
            description: "Choose a configuration to base your report on",
            content: (
              <Container>
                <SpaceBetween direction="vertical" size="l">
                  <FormField
                    label="Configuration"
                    description="Select the configuration that defines how to create the report"
                  >
                    <Select
                      selectedOption={selectedConfiguration}
                      onChange={({ detail }) => setSelectedConfiguration(detail.selectedOption)}
                      options={configurationOptions}
                      filteringType="auto"
                      placeholder="Select a configuration"
                      empty="No configurations available"
                      loadingText="Loading configurations"
                      statusType={configurationsLoading ? "loading" : "finished"}
                    />
                  </FormField>

                  {selectedConfiguration && (
                    <Container>
                      <Header variant="h3">Configuration Details</Header>
                      <KeyValuePairs
                        items={[
                          {
                            label: "Configuration ID",
                            value: selectedConfiguration.value,
                          },
                          {
                            label: "Description",
                            value: selectedConfiguration.description || "No description",
                          },
                          // TODO: Add more configuration details if available
                        ]}
                      />
                    </Container>
                  )}
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Select Case",
            info: <Link variant="info">Info</Link>,
            description: "Choose a case containing the source documents and artifacts",
            content: (
              <Container>
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Case Context" description="Select the case from which to create the report">
                    <Select
                      selectedOption={selectedCase}
                      onChange={({ detail }) => setSelectedCase(detail.selectedOption)}
                      options={caseOptions}
                      filteringType="auto"
                      placeholder="Select a case"
                      empty="No cases available"
                      loadingText="Loading cases"
                      statusType={casesLoading ? "loading" : "finished"}
                    />
                  </FormField>

                  {selectedCase && (
                    <Container>
                      <Header variant="h3">Case Details</Header>
                      <KeyValuePairs
                        items={[
                          {
                            label: "Case ID",
                            value: selectedCase.value,
                          },
                          {
                            label: "Description",
                            value: selectedCase.description || "No description",
                          },
                          // TODO: Add more case details if available
                        ]}
                      />
                    </Container>
                  )}
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Review and Create",
            content: (
              <SpaceBetween size="l">
                <Container
                  header={
                    <Header variant="h3" actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}>
                      General Information
                    </Header>
                  }
                >
                  <KeyValuePairs
                    columns={3}
                    items={[
                      {
                        label: "Report Name",
                        value: reportName || "-",
                      },
                      {
                        label: "Report Description",
                        value: reportDescription || "-",
                      },
                    ]}
                  />
                </Container>

                <Container
                  header={
                    <Header variant="h3" actions={<Button onClick={() => setActiveStepIndex(1)}>Edit</Button>}>
                      Configuration
                    </Header>
                  }
                >
                  <KeyValuePairs
                    columns={3}
                    items={[
                      {
                        label: "Configuration Name",
                        value: selectedConfiguration ? selectedConfiguration.label : "-",
                      },
                      {
                        label: "Configuration ID",
                        value: selectedConfiguration ? selectedConfiguration.value : "-",
                      },
                    ]}
                  />
                </Container>

                <Container
                  header={
                    <Header variant="h3" actions={<Button onClick={() => setActiveStepIndex(2)}>Edit</Button>}>
                      Case
                    </Header>
                  }
                >
                  <KeyValuePairs
                    columns={3}
                    items={[
                      {
                        label: "Case Name",
                        value: selectedCase ? selectedCase.label : "-",
                      },
                      {
                        label: "Case ID",
                        value: selectedCase ? selectedCase.value : "-",
                      },
                    ]}
                  />
                </Container>
              </SpaceBetween>
            ),
          },
        ]}
      />
    </Container>
  );
};
