import { FunctionComponent, useEffect, useState } from "react";
import { FluxWidgetProps } from "../../common/standards";
import { CaseContext, CustomArtifact, QueryRecord, Source } from "../../common/types/models";
import { Container, CopyToClipboard, Header, SpaceBetween, StatusIndicator } from "@cloudscape-design/components";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import {
  getCaseContext,
  listQueryRecords,
  queryFromSource,
  updateCaseContext,
  updateQueryRecord,
  uploadSource,
} from "../../api/caseContext";
import {
  createFluxNotification,
  encodeBase64RemovePrefix,
  extractSourceIdFromPath,
  extractSourceTypeFromPath,
  renderErrorMessage,
} from "../../common/helpers";
import { SourceType } from "../../common/enums";
import { SourceTable, SourceTableItem } from "../table/SourceTable";
import { QueryRecordTable } from "../table/QueryRecordTable";
import { QueryRecordView } from "./QueryRecordView";
import { CustomArtifactTable } from "../table/CustomArtifactTable";

export interface CaseOverviewProps extends FluxWidgetProps {
  contextId: string;
  onChange: (caseContext: CaseContext) => void;
}

const renderSourceTableItems = (caseContext: CaseContext): SourceTableItem[] => {
  if (!caseContext.sources) return [];
  return caseContext.sources.map((s) => {
    return {
      fullPath: s.s3Path,
      type: extractSourceTypeFromPath(s.s3Path),
      sourceId: extractSourceIdFromPath(s.s3Path),
      name: s.name,
    };
  });
};

export const CaseOverview: FunctionComponent<CaseOverviewProps> = ({
  contextId,
  onChange,
  setNotifications,
  notifications,
  accessToken,
}) => {
  const [queryRecords, setQueryRecords] = useState<QueryRecord[]>([]);
  const [currentQueryRecord, setCurrentQueryRecord] = useState<QueryRecord | undefined>(undefined);
  const [caseContext, setCaseContext] = useState<CaseContext | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchCaseContext = async () => {
    setLoading(true);
    if (contextId && accessToken) {
      const caseContext = await getCaseContext(accessToken, { contextId });
      setCaseContext(caseContext);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCaseContext();
  }, [contextId, accessToken]);

  const refreshQueryRecords = async () => {
    if (caseContext?.contextId && accessToken) {
      const records = await listQueryRecords(accessToken, {
        contextId: caseContext.contextId,
      });
      setQueryRecords(records);
    }
  };

  useEffect(() => {
    refreshQueryRecords();
  }, [caseContext, accessToken]);

  if (!caseContext) return null;

  const handleSourceUpload = async (source: File, vanityName: string, type: SourceType) => {
    if (accessToken && caseContext) {
      const context = await uploadSource(accessToken, {
        contextId: caseContext.contextId,
        content: await encodeBase64RemovePrefix(source),
        sourceType: type,
        sourceFormat: "PDF",
        sourceName: vanityName,
      });
      console.log(context);
      onChange(context);
      setCaseContext(context);
    }
  };

  const handleSourceUpdate = async (item: SourceTableItem) => {
    if (accessToken && caseContext) {
      const updatedCaseContext = await updateCaseContext(accessToken, {
        caseContext: {
          ...caseContext,
          sources: caseContext.sources.map((source: Source) => {
            if (source.s3Path === item.fullPath) {
              return {
                ...source,
                name: item.name,
              };
            } else {
              return source;
            }
          }),
        },
      });
      onChange(updatedCaseContext);
      setCaseContext(updatedCaseContext);
    }
  };

  const handleQuery = async (sourceS3Paths: string[], configurationId: string) => {
    if (accessToken && caseContext) {
      try {
        const record = await queryFromSource(accessToken, {
          sourceS3Paths: sourceS3Paths,
          contextId: caseContext.contextId,
          configurationId: configurationId,
        });
        setQueryRecords([...queryRecords, record]);
      } catch (error: any) {
        createFluxNotification({
          header: "Failed to query",
          content: renderErrorMessage(error),
          type: "error",
          id: "query_failed",
          setNotifications,
          notifications,
        });
      }
    }
  };

  return (
    <SpaceBetween size="xl">
      <Container header={<Header variant="h2">Case Overview</Header>}>
        <KeyValuePairs
          columns={3}
          items={[
            {
              label: "Context ID",
              value: (
                <CopyToClipboard
                  copyButtonAriaLabel="Copy Context ID"
                  copyErrorText="Context ID failed to copy"
                  copySuccessText="Context ID copied"
                  textToCopy={caseContext.contextId}
                  variant="inline"
                />
              ),
            },
            {
              label: "Name",
              value: caseContext.name,
            },
            {
              label: "Status",
              value: <StatusIndicator>{caseContext.status}</StatusIndicator>,
            },
            {
              label: "Description",
              value: caseContext.description,
            },
            {
              label: "Updated At",
              value: caseContext.updatedAt,
            },
            {
              label: "Created At",
              value: caseContext.createdAt,
            },
          ]}
        />
      </Container>
      <SourceTable
        onSourceUpload={handleSourceUpload}
        onQuery={handleQuery}
        onSourceUpdate={handleSourceUpdate}
        loading={false}
        onItemClicked={(item: SourceTableItem) => {}}
        items={renderSourceTableItems(caseContext)}
        onRefresh={fetchCaseContext}
        accessToken={accessToken}
      />
      <QueryRecordTable
        items={queryRecords}
        loading={false}
        onRefresh={fetchCaseContext}
        onItemClicked={(item: QueryRecord) => {
          setCurrentQueryRecord(item);
        }}
        accessToken={accessToken}
      />
      <QueryRecordView
        caseContext={caseContext}
        accessToken={accessToken}
        record={currentQueryRecord}
        onChange={async (record) => {
          if (accessToken) {
            const result = await updateQueryRecord(accessToken, record);
            refreshQueryRecords();
            return result;
          } else {
            createFluxNotification({
              header: "Failed to update record",
              content: "Failed to update record. Refresh the page and try again.",
              type: "error",
              id: "update_record_failed_session_expired",
              setNotifications,
              notifications,
            });
          }
        }}
      />
      <CustomArtifactTable
        caseContext={caseContext}
        onRefresh={async () => {
          await fetchCaseContext();
        }}
        onItemClicked={(item: CustomArtifact) => {}}
        accessToken={accessToken}
        loading={loading}
        items={caseContext.artifacts || []}
      />
    </SpaceBetween>
  );
};
