import ContentLayout from "@cloudscape-design/components/content-layout";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";
import { useCognito } from "../auth/cognito";
import { useEffect, useState } from "react";
import { AuthUser, signOut } from "aws-amplify/auth";
import { ConsoleRouteConfig } from "../common/routes";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Form from "@cloudscape-design/components/form";

export const ProfileView = () => {
  const { cognitoLoading, authenticated, fetchCognitoUser, changeUserPassword } = useCognito();
  const [authUser, setAuthUser] = useState<AuthUser | undefined>(undefined);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordUpdateLoading, setPasswordUpdateLoading] = useState(false);
  const [passwordUpdateError, setPasswordUpdateError] = useState<string | null>(null);

  useEffect(() => {
    if (cognitoLoading) return;
    if (!authenticated) {
      window.location.href = `#${ConsoleRouteConfig.auth.href}`;
    } else {
      fetchCognitoUser().then((user) => {
        setAuthUser(user);
      });
    }
  }, [cognitoLoading, authenticated]);

  const handlePasswordUpdate = async () => {
    if (newPassword !== confirmNewPassword) {
      setPasswordUpdateError("New password and confirm new password do not match");
      return;
    }
    setPasswordUpdateLoading(true);
    setPasswordUpdateError(null);
    try {
      await changeUserPassword(oldPassword, newPassword);
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      alert("Password updated successfully");
    } catch (error) {
      setPasswordUpdateError("Failed to update password");
      console.error("Failed to update password", error);
    } finally {
      setPasswordUpdateLoading(false);
    }
  };

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Profile
        </Header>
      }
    >
      <SpaceBetween size="xl">
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="normal"
                    onClick={() => {
                      signOut().then(() => {
                        window.location.href = `#${ConsoleRouteConfig.auth.href}`;
                      });
                    }}
                  >
                    Sign Out
                  </Button>
                  <Button variant="primary">Edit</Button>
                </SpaceBetween>
              }
            >
              General
            </Header>
          }
        >
          <KeyValuePairs
            columns={2}
            items={[
              { label: "Username", value: authUser?.username },
              { label: "Password", value: "*******" },
            ]}
          />
        </Container>

        <Container header={<Header variant="h2">Update Password</Header>}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Form
              variant="embedded"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="primary" loading={passwordUpdateLoading} onClick={handlePasswordUpdate}>
                    Submit
                  </Button>
                </SpaceBetween>
              }
              header={<Header variant="h3">Form header</Header>}
            >
              <SpaceBetween size="l">
                <FormField label="Old Password">
                  <Input type="password" value={oldPassword} onChange={(event) => setOldPassword(event.detail.value)} />
                </FormField>
                <FormField label="New Password" errorText={passwordUpdateError}>
                  <Input type="password" value={newPassword} onChange={(event) => setNewPassword(event.detail.value)} />
                </FormField>
                <FormField label="Confirm New Password" description="Please enter the new password again to confirm">
                  <Input
                    type="password"
                    value={confirmNewPassword}
                    onChange={(event) => setConfirmNewPassword(event.detail.value)}
                  />
                </FormField>
              </SpaceBetween>
            </Form>
          </form>
        </Container>
      </SpaceBetween>
    </ContentLayout>
  );
};
