import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCognito } from "../auth/cognito";
import AppLayout from "@cloudscape-design/components/app-layout";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";
import Tabs from "@cloudscape-design/components/tabs";
import { ConsoleRouteConfig } from "../common/routes";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Link from "@cloudscape-design/components/link";
import { CreateConfigurationWizard } from "../component/wizard/CreateConfigurationWizard";
import { CreateReportWizard } from "../component/wizard/CreateReportWizard";

export const ReportView = () => {
  const { id } = useParams<{ id: string }>();
  const { cognitoLoading, authenticated, accessToken } = useCognito();

  useEffect(() => {
    if (cognitoLoading) return;
    if (!authenticated || !accessToken) {
      window.location.href = `#${ConsoleRouteConfig.auth.href}`;
    }
  }, [cognitoLoading, authenticated, accessToken]);

  const [notifications, setNotifications] = React.useState<FlashbarProps.MessageDefinition[]>([]);

  React.useEffect(() => {
    // TODO: Implement
  }, [accessToken]);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      notifications={<Flashbar items={notifications} />}
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Report
        </Header>
      }
    >
      <SpaceBetween size="xl">
        <CreateReportWizard
          accessToken={accessToken}
          onSubmit={function (): void {
            throw new Error("Function not implemented.");
          }}
          onCancel={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      </SpaceBetween>
    </ContentLayout>
  );
};
