import { FunctionComponent, useEffect, useState } from "react";
import { AnswerType } from "../../common/enums";
import Input from "@cloudscape-design/components/input";
import FormField from "@cloudscape-design/components/form-field";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Textarea from "@cloudscape-design/components/textarea";
import Select from "@cloudscape-design/components/select";
import { Answer } from "../../common/types/models";
import { FluxWidgetProps } from "../../common/standards";
import Container from "@cloudscape-design/components/container";
import { Button, Header, Icon } from "@cloudscape-design/components";
import { QueryItem } from "./QueryRecordView";

export interface QueryItemViewProps extends FluxWidgetProps {
  item: QueryItem | null;
  inEdit: boolean;
  onChange: (a: QueryItem) => void;
}

export const QueryItemView: FunctionComponent<QueryItemViewProps> = ({ item, inEdit, onChange }) => {
  if (!item) return null;

  return (
    <SpaceBetween size={"m"}>
      {item &&
        item.answers.map((answer, i) => (
          <Container
            key={i}
            footer={
              <Button
                variant="link"
                iconName="remove"
                disabled={!inEdit}
                onClick={() => {
                  onChange({
                    ...item,
                    answers: item.answers.filter((_, j) => j !== i),
                  });
                }}
              >
                Delete
              </Button>
            }
          >
            <SpaceBetween size={"s"}>
              <FormField label={"Value"}>
                <Textarea
                  value={answer.value}
                  disabled={!inEdit}
                  onChange={(e) => {
                    onChange({
                      ...item,
                      answers: item.answers.map((a, j) => (j === i ? { ...a, value: e.detail.value } : a)),
                    });
                  }}
                />
              </FormField>
              <FormField label={"Unit"}>
                <Input
                  value={answer.unit || ""}
                  disabled={!inEdit}
                  onChange={(e) => {
                    onChange({
                      ...item,
                      answers: item.answers.map((a, j) => (j === i ? { ...a, unit: e.detail.value } : a)),
                    });
                  }}
                />
              </FormField>
              <FormField label={"Type"}>
                <Select
                  selectedOption={{
                    value: answer.type,
                    label: answer.type,
                  }}
                  disabled={!inEdit}
                  options={Object.values(AnswerType).map((type) => ({ value: type, label: type }))}
                  onChange={(e) => {
                    onChange({
                      ...item,
                      answers: item.answers.map((a, j) => (j === i ? { ...a, type: e.detail.selectedOption.value as AnswerType } : a)),
                    });
                  }}
                />
              </FormField>
              <FormField label={"Citation"}>
                <Textarea value={answer.citation || ""} disabled={!inEdit} />
              </FormField>
            </SpaceBetween>
          </Container>
        ))}
      <Button
        variant="normal"
        onClick={() => {
          onChange({
            ...item,
            answers: item.answers.concat({
              value: "",
              unit: "",
              type: AnswerType.STRING,
              citation: "",
            }),
          });
        }}
        fullWidth
      >
        Add Item
      </Button>
    </SpaceBetween>
  );
};
