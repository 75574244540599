import { FunctionComponent, useEffect, useState } from "react";
import ace from "ace-builds";
import "ace-builds/css/ace.css";
import "ace-builds/css/theme/cloud_editor.css";
import "ace-builds/css/theme/cloud_editor_dark.css";
import "ace-builds/webpack-resolver";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import Form from "@cloudscape-design/components/form";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import CodeEditor from "@cloudscape-design/components/code-editor";
import { FluxWidgetProps } from "../../common/standards";
import { Configuration } from "../../common/types/models";
import { getConfiguration, updateConfiguration } from "../../api/configurations";
import Textarea from "@cloudscape-design/components/textarea";
import SegmentedControl from "@cloudscape-design/components/segmented-control";
import { CodeView } from "@cloudscape-design/code-view";
import { ConsoleRouteConfig } from "../../common/routes";

const i18nStrings = {
  loadingState: "Loading code editor",
  errorState: "There was an error loading the code editor.",
  errorStateRecovery: "Retry",

  editorGroupAriaLabel: "Code editor",
  statusBarGroupAriaLabel: "Status bar",

  cursorPosition: (row: any, column: any) => `Ln ${row}, Col ${column}`,
  errorsTab: "Errors",
  warningsTab: "Warnings",
  preferencesButtonAriaLabel: "Preferences",

  paneCloseButtonAriaLabel: "Close",

  preferencesModalHeader: "Preferences",
  preferencesModalCancel: "Cancel",
  preferencesModalConfirm: "Confirm",
  preferencesModalWrapLines: "Wrap lines",
  preferencesModalTheme: "Theme",
  preferencesModalLightThemes: "Light themes",
  preferencesModalDarkThemes: "Dark themes",
};

export interface ConfigurationEditorProps extends FluxWidgetProps {
  configurationId: string;
}

export const ConfigurationEditor: FunctionComponent<ConfigurationEditorProps> = ({ configurationId, accessToken }) => {
  const [config, setConfig] = useState<Configuration | undefined>(undefined);
  const [aceInstance, setAceInstance] = useState();
  const [preferences, setPreferences] = useState({});
  const [editorLoading, setEditorLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [mode, setMode] = useState("view");
  const [readonly, setReadonly] = useState(true);

  useEffect(() => {
    async function loadAce() {
      ace.config.set("useStrictCSP", true);
      return ace;
    }
    Promise.all([loadAce()])
      .then(([a]) => setAceInstance(a as any))
      .finally(() => {
        setEditorLoading(false);
      });
  }, []);

  async function fetchConfiguration() {
    if (accessToken) {
      try {
        const configuration = await getConfiguration(accessToken, configurationId);
        console.log(configuration);
        setConfig(configuration);
      } catch (error) {
        console.error("Failed to fetch configuration", error);
      }
    }
  }

  useEffect(() => {
    fetchConfiguration();
  }, [accessToken, configurationId]);

  useEffect(() => {
    if (mode === "edit") {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
  }, [mode]);

  const handleUpdate = async () => {
    if (config && accessToken) {
      setUpdating(true);
      try {
        const updatedConfig = await updateConfiguration(accessToken, {
          ...config,
          content: JSON.stringify(JSON.parse(config.content), null, 2),
        });
        setConfig(updatedConfig);
      } catch (error) {
        console.error("Failed to update configuration", error);
      } finally {
        setUpdating(false);
      }
    }
  };

  return (
    <Container
      variant="default"
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <SegmentedControl
                selectedId={mode}
                onChange={({ detail }) => setMode(detail.selectedId)}
                label="Default segmented control"
                options={[
                  { text: "View", id: "view" },
                  { text: "Edit", id: "edit" },
                ]}
              />
            </SpaceBetween>
          }
        >{`ID: ${config?.configurationId}`}</Header>
      }
    >
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="normal"
              onClick={() => {
                window.location.href = `#${ConsoleRouteConfig.configuration.href}`;
              }}
            >
              Back
            </Button>
            <Button variant="primary" onClick={handleUpdate} loading={updating}>
              Update
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size="l">
          <FormField label="Name">
            <Input
              readOnly={readonly}
              value={config?.name || ""}
              onChange={(event) => {
                if (config) setConfig({ ...config, name: event.detail.value });
              }}
            />
          </FormField>
          <FormField label="Type">
            <Input
              readOnly={readonly}
              value={config?.type || ""}
              onChange={(event) => {
                if (config) setConfig({ ...config, type: event.detail.value });
              }}
            />
          </FormField>
          <FormField label="Description">
            <Textarea
              readOnly={readonly}
              value={config?.description || ""}
              onChange={(event) => {
                if (config) setConfig({ ...config, description: event.detail.value });
              }}
            />
          </FormField>
          <FormField label="Content">
            {readonly ? (
              <CodeView content={config?.content || "{}"} lineNumbers={true} />
            ) : (
              <CodeEditor
                ace={aceInstance}
                value={config?.content || "{}"}
                language="json"
                onDelayedChange={(event) => {
                  if (config) setConfig({ ...config, content: event.detail.value });
                }}
                preferences={preferences}
                onPreferencesChange={(event) => setPreferences(event.detail)}
                loading={editorLoading}
                i18nStrings={i18nStrings}
                themes={{ light: ["cloud_editor"], dark: ["cloud_editor_dark"] }}
              />
            )}
          </FormField>
        </SpaceBetween>
      </Form>
    </Container>
  );
};
