import { BreadcrumbGroup } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ConsolePageIds, ConsoleRouteConfig } from "../common/routes";

export const FluxBreadcrumbs = () => {
  const [items, setItems] = useState([
    {
      text: ConsoleRouteConfig.home.pageName,
      href: `#${ConsoleRouteConfig.home.href}`,
    },
  ]);

  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const newItems = [
      {
        text: ConsoleRouteConfig.home.pageName,
        href: `#${ConsoleRouteConfig.home.href}`,
      },
    ];

    pathSegments.forEach((segment, index) => {
      const href = `#/${pathSegments.slice(0, index + 1).join("/")}`;
      let text = ConsoleRouteConfig[segment as ConsolePageIds]?.pageName;
      if (!text) text = segment;

      newItems.push({
        text,
        href,
      });
    });

    setItems(newItems);
    console.log("location", location.pathname);
  }, [location]);

  return <BreadcrumbGroup items={items} />;
};
