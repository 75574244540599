import * as React from "react";

import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";

import { CaseContext } from "../common/types/models";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Link from "@cloudscape-design/components/link";
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { CaseContextTable } from "../component/table/CaseContextTable";
import { listCaseContexts } from "../api/caseContext";
import { CreateCaseWizard } from "../component/wizard/CreateCaseWizard";
import { CaseOverview } from "../component/views/CaseOverview";
import { useEffect, useState } from "react";
import { useCognito } from "../auth/cognito";
import { ConsoleRouteConfig } from "../common/routes";
import { useParams } from "react-router-dom";

export const CaseView = () => {
  const { id } = useParams<{ id: string }>();

  const [caseContextsLoading, setCaseContextsLoading] = useState(false);

  const { cognitoLoading, authenticated, accessToken } = useCognito();

  useEffect(() => {
    if (cognitoLoading) return;
    if (!authenticated || !accessToken) {
      window.location.href = `#${ConsoleRouteConfig.auth.href}`;
    }
  }, [cognitoLoading, authenticated, accessToken]);

  const [notifications, setNotifications] = React.useState<FlashbarProps.MessageDefinition[]>([]);
  const [caseContexts, setCaseContexts] = React.useState<CaseContext[]>([]);

  const refreshCaseContexts = async () => {
    setCaseContextsLoading(true);
    if (!accessToken) return;
    const caseContexts = await listCaseContexts(accessToken);
    setCaseContexts(caseContexts);
    setCaseContextsLoading(false);
  };

  React.useEffect(() => {
    refreshCaseContexts();
  }, [accessToken]);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      notifications={<Flashbar items={notifications} />}
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Case
        </Header>
      }
    >
      <SpaceBetween size="xl">
        <CaseContextTable
          accessToken={accessToken}
          items={caseContexts}
          loading={caseContextsLoading}
          onRefresh={refreshCaseContexts}
          onItemClicked={(item) => {
            console.log(item);
          }}
          notifications={notifications}
          setNotifications={setNotifications}
        />
        {id && (
          <CaseOverview
            onChange={async (_) => {
              if (!accessToken) return;
              await refreshCaseContexts();
            }}
            accessToken={accessToken}
            contextId={id}
            notifications={notifications}
            setNotifications={setNotifications}
          />
        )}
        {!id && (
          <CreateCaseWizard
            accessToken={accessToken}
            onSubmit={() => {
              refreshCaseContexts();
            }}
            onCancel={() => {}}
            notifications={notifications}
            setNotifications={setNotifications}
          />
        )}
      </SpaceBetween>
    </ContentLayout>
  );
};
