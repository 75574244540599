import { FunctionComponent, useState } from "react";
import { FluxTableWidgetProps } from "../../common/standards";
import { QueryRecord } from "../../common/types/models";
import Table from "@cloudscape-design/components/table";
import Link from "@cloudscape-design/components/link";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import { Container, CopyToClipboard } from "@cloudscape-design/components";
import { renderShortUUID } from "../../common/helpers";
import { deleteQueryRecord } from "../../api/caseContext";

export interface QueryRecordTableProps extends FluxTableWidgetProps<QueryRecord> {}

export const QueryRecordTable: FunctionComponent<QueryRecordTableProps> = ({
  items,
  onItemClicked,
  onRefresh,
  accessToken,
}) => {
  const [selectedItems, setSelectedItems] = useState<QueryRecord[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDeleteQueryRecord = async (queryRecord: QueryRecord) => {
    if (!accessToken) return;
    await deleteQueryRecord(accessToken, { queryId: queryRecord.queryId, contextId: queryRecord.contextId });
    setRefreshing(true);
    onRefresh?.().finally(() => setRefreshing(false));
  };

  return (
    <Container>
      <Table
        columnDefinitions={[
          {
            id: "queryId",
            header: "Query ID",
            cell: (item) => (
              <Link
                onClick={() => {
                  onItemClicked(item);
                }}
              >
                {renderShortUUID(item.queryId) || "-"}
              </Link>
            ),
            // TODO: download source document on click
            sortingField: "queryId",
            isRowHeader: true,
          },
          {
            id: "createdAt",
            header: "Created At",
            cell: (item) => item.createdAt || "-",
            sortingField: "createdAt",
          },
          {
            id: "updatedAt",
            header: "Updated At",
            cell: (item) => item.updatedAt || "-",
            sortingField: "updatedAt",
          },
        ]}
        enableKeyboardNavigation
        items={items}
        loadingText="Loading resources"
        sortingDisabled
        variant="embedded"
        selectionType="multi"
        trackBy="queryId"
        selectedItems={selectedItems}
        onSelectionChange={(e) => {
          setSelectedItems(e.detail.selectedItems);
        }}
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No records</b>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            variant="h3"
            actions={
              <SpaceBetween size="xs" direction="horizontal">
                <Button
                  onClick={() => {
                    setRefreshing(true);
                    onRefresh?.().finally(() => setRefreshing(false));
                  }}
                  iconName="refresh"
                  loading={refreshing}
                  disabled={onRefresh === undefined}
                ></Button>
                <Button
                  loading={deleting}
                  onClick={async () => {
                    setDeleting(true);
                    await Promise.all(selectedItems.map(handleDeleteQueryRecord));
                    setSelectedItems([]);
                    setDeleting(false);
                  }}
                  disabled={selectedItems.length === 0}
                >
                  Delete
                </Button>
              </SpaceBetween>
            }
          >
            Query Records
          </Header>
        }
      />
    </Container>
  );
};
