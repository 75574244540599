import { FunctionComponent, useEffect } from "react";
import { FluxWidgetProps } from "../../common/standards";
import { useState } from "react";

import FileDropzone from "@cloudscape-design/components/file-dropzone";
import FileInput from "@cloudscape-design/components/file-input";
import FileTokenGroup from "@cloudscape-design/components/file-token-group";
import CodeView from "@cloudscape-design/code-view/code-view";
import { useCognito } from "../../auth/cognito";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Textarea from "@cloudscape-design/components/textarea";
import Box from "@cloudscape-design/components/box";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import Wizard from "@cloudscape-design/components/wizard";
import { CreateConfigurationRequest } from "../../common/types/requests";

export interface CreateConfigurationWizardProps extends FluxWidgetProps {
  onSubmit: (c: CreateConfigurationRequest) => Promise<void>;
  onCancel: () => void;
}

export const CreateConfigurationWizard: FunctionComponent<CreateConfigurationWizardProps> = ({
  onSubmit,
  onCancel,
}) => {
  const [configurationId, setConfigurationId] = useState<string>("");
  const [name, setName] = useState<string>("Untitled");
  const [type, setType] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [value, setValue] = useState<File[]>([]);
  const [creating, setCreating] = useState<boolean>(false);

  const { authUser } = useCognito();

  useEffect(() => {
    console.log(authUser);
    if (authUser) {
      const today = new Date().toISOString().split("T")[0];
      const configId = `${authUser.username.toLowerCase()}.${name.toLowerCase().replace(/\s+/g, "-")}.${today}`;
      setConfigurationId(configId);
    }
  }, [authUser, name]);

  const reset = () => {
    setConfigurationId("");
    setName("Untitled");
    setType("");
    setDescription("");
    setContent("");
    setValue([]);
  };

  const handleFileUpload = (files: File[]) => {
    if (files.length === 0) return;
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setContent(e.target ? (e.target.result as string) : "");
    };
    reader.readAsText(file);
  };

  const steps = [
    {
      title: "Configuration Details",
      content: (
        <Container>
          <SpaceBetween size="m">
            <FormField label="Name">
              <Input value={name} onChange={(event) => setName(event.detail.value)} />
            </FormField>
            <FormField label="Custom ID">
              <Input value={configurationId} onChange={(event) => setConfigurationId(event.detail.value)} />
            </FormField>
            <FormField label="Type">
              <Input value={type} onChange={(event) => setType(event.detail.value)} />
            </FormField>
            <FormField label="Description">
              <Textarea value={description} onChange={(event) => setDescription(event.detail.value)} />
            </FormField>
            <FormField label="Upload Configuration File">
              <SpaceBetween size="l">
                <FileDropzone
                  onChange={({ detail }) => {
                    setValue(detail.value);
                    handleFileUpload(detail.value);
                  }}
                >
                  <SpaceBetween size="xxs" alignItems="center">
                    <Box color="inherit">Drop file here or select from below</Box>
                    <FileInput
                      value={value}
                      onChange={({ detail }) => {
                        setValue(detail.value);
                        handleFileUpload(detail.value);
                      }}
                      multiple={false}
                    >
                      Choose file
                    </FileInput>
                  </SpaceBetween>
                </FileDropzone>
                <FileTokenGroup
                  items={value.map((file) => ({ file }))}
                  onDismiss={({ detail }) =>
                    setValue((value) => value.filter((_, index) => index !== detail.fileIndex))
                  }
                  i18nStrings={{
                    removeFileAriaLabel: () => "Remove file",
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                    warningIconAriaLabel: "Warning",
                  }}
                />
              </SpaceBetween>
            </FormField>
          </SpaceBetween>
        </Container>
      ),
    },
    {
      title: "Review & Submit",
      content: (
        <SpaceBetween size="s">
          <KeyValuePairs
            columns={3}
            items={[
              { label: "Name", value: name },
              { label: "Type", value: type },
              { label: "Description", value: description },
            ]}
          ></KeyValuePairs>
          <FormField label="Content">
            <CodeView content={content} lineNumbers={true} />
          </FormField>
        </SpaceBetween>
      ),
    },
  ];

  return (
    <Container header={<Header variant="h2">Create New Configuration</Header>}>
      <Wizard
        steps={steps}
        submitButtonText="Create"
        isLoadingNextStep={creating}
        onSubmit={async () => {
          setCreating(true);
          await onSubmit({
            configurationId,
            name,
            type,
            description,
            content,
          });
          setCreating(false);
          reset();
        }}
        onCancel={() => {
          reset();
          onCancel();
        }}
      />
    </Container>
  );
};
