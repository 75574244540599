import axios from "axios";
import { EndpointConfigs } from "./endpointConfigs";
import { Configuration } from "../common/types/models";
import { CreateConfigurationRequest } from "../common/types/requests";

const CONFIGURATIONS_API_URL = EndpointConfigs.configurationsApiUrl();

export const createConfiguration = async (accessToken: string, request: CreateConfigurationRequest) => {
  const response = await axios.post(`${CONFIGURATIONS_API_URL}/v0`, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "CreateConfiguration",
      Authorization: accessToken,
    },
  });
  return response.data.configuration as Configuration;
};

export const listConfigurations = async (accessToken: string, ownerId?: string) => {
  const response = await axios.post(
    `${CONFIGURATIONS_API_URL}/v0`,
    { ownerId },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ListConfigurations",
        Authorization: accessToken,
      },
    }
  );
  return response.data.configurations as Configuration[];
};

export const getConfiguration = async (accessToken: string, configurationId: string) => {
  const response = await axios.post(
    `${CONFIGURATIONS_API_URL}/v0`,
    { configurationId },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "GetConfiguration",
        Authorization: accessToken,
      },
    }
  );
  return response.data.configuration as Configuration;
};

export const deleteConfiguration = async (accessToken: string, configurationId: string) => {
  const response = await axios.post(
    `${CONFIGURATIONS_API_URL}/v0`,
    { configurationId },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "DeleteConfiguration",
        Authorization: accessToken,
      },
    }
  );
  return response.data.message;
};

export const updateConfiguration = async (accessToken: string, configuration: Configuration) => {
  const response = await axios.post(
    `${CONFIGURATIONS_API_URL}/v0`,
    { configuration },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "UpdateConfiguration",
        Authorization: accessToken,
      },
    }
  );
  return response.data.configuration as Configuration;
};
