const localDevStageOverride = "alpha";

export class EndpointConfigs {
  static reportGenerationApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.report-generation-engine.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.report-generation-engine.flux-systems.com";
    }
    return "https://beta.report-generation-engine.flux-systems.com";
  }

  static unifiedWorkflowApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.unified-workflow.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.unified-workflow.flux-systems.com";
    }
    return "https://beta.unified-workflow.flux-systems.com";
  }

  static caseContextApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.case-context.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.case-context.flux-systems.com";
    }
    return "https://beta.case-context.flux-systems.com";
  }

  static authApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.auth.flux-systems.com/v0`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.auth.flux-systems.com/v0";
    }
    return "https://beta.auth.flux-systems.com/v0";
  }

  static configurationsApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.configurations.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.configurations.flux-systems.com";
    }
    return "https://beta.configurations.flux-systems.com";
  }
}
