import Select, { SelectProps } from "@cloudscape-design/components/select";
import { FluxWidgetProps } from "../../common/standards";
import { Configuration } from "../../common/types/models";
import { FunctionComponent, useEffect, useState } from "react";
import { listConfigurations } from "../../api/configurations";
import { createFluxNotification } from "../../common/helpers";

export interface ConfigurationSelectorProps extends FluxWidgetProps {
  onConfigurationSelected: (configuration: Configuration | undefined) => void;
}

function configurationToSelectOption(cfg: Configuration): SelectProps.Option {
  return {
    label: cfg.name || "Untitled",
    value: cfg.configurationId || "no-id",
    description: cfg.type || "Unknown",
    tags: [cfg.configurationId || "no-id"],
  };
}

export const ConfigurationSelector: FunctionComponent<ConfigurationSelectorProps> = ({
  onConfigurationSelected,
  notifications,
  setNotifications,
  accessToken,
}) => {
  const [fluxConfigurations, setFluxConfigurations] = useState<Configuration[]>([]);
  const [customConfigurations, setCustomConfigurations] = useState<Configuration[]>([]);
  const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(null);

  async function fetchConfigurations(accessToken: string) {
    try {
      setCustomConfigurations(await listConfigurations(accessToken));
      setFluxConfigurations(await listConfigurations(accessToken, "flux"));
    } catch (error) {
      createFluxNotification({
        header: "Failed to fetch configurations",
        content: "Failed to fetch configurations",
        type: "error",
        id: "selector_fetch_configurations_error",
        setNotifications,
        notifications,
      });
    }
  }

  useEffect(() => {
    if (accessToken) {
      fetchConfigurations(accessToken);
    }
  }, []);

  function handleSelectChange(event: SelectProps.ChangeDetail) {
    setSelectedOption(event.selectedOption);
    console.log(event.selectedOption);
    const selectedConfig = [...fluxConfigurations, ...customConfigurations].find(
      (c) => (c.configurationId || "no-id") === event.selectedOption.value
    );
    onConfigurationSelected(selectedConfig || undefined);
  }

  return (
    <Select
      selectedOption={selectedOption}
      onChange={(e) => handleSelectChange(e.detail)}
      options={[
        {
          label: "Flux Configurations",
          options: fluxConfigurations.map(configurationToSelectOption),
        },
        {
          label: "Custom Configurations",
          options: customConfigurations.map(configurationToSelectOption),
        },
      ]}
      placeholder="Select query configuration"
      selectedAriaLabel="Selected"
      filteringType="auto"
    />
  );
};
