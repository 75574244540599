import { FunctionComponent, useEffect, useState } from "react";
import { FluxTableWidgetProps } from "../../common/standards";
import Table from "@cloudscape-design/components/table";
import Link from "@cloudscape-design/components/link";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import FileUpload from "@cloudscape-design/components/file-upload";
import Input from "@cloudscape-design/components/input";
import Select from "@cloudscape-design/components/select";
import { removeArtifact, updateCaseContext, uploadArtifact } from "../../api/caseContext";
import { Container } from "@cloudscape-design/components";
import { CaseContext, CustomArtifact } from "../../common/types/models";
import { encodeBase64RemovePrefix } from "../../common/helpers";

export interface CustomArtifactTableProps extends FluxTableWidgetProps<CustomArtifact> {
  caseContext?: CaseContext;
}

export const CustomArtifactTable: FunctionComponent<CustomArtifactTableProps> = ({
  items,
  onRefresh,
  accessToken,
  caseContext,
  loading,
}) => {
  const [selectedItems, setSelectedItems] = useState<CustomArtifact[]>([]);
  const [artifactName, setArtifactName] = useState<string>("");
  const [artifactType, setArtifactType] = useState<string>("PDF");
  const [artifact, setArtifact] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [showUploadPanel, setShowUploadPanel] = useState<boolean>(false);

  const [removing, setRemoving] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  useEffect(() => {
    setSelectedItems([]);
  }, [items]);

  const handleRemoveArtifact = async () => {
    setRemoving(true);
    try {
      await Promise.all(
        selectedItems.map((item) =>
          removeArtifact(accessToken!, {
            contextId: item.contextId,
            artifactS3Path: item.artifactS3Path,
          })
        )
      );
    } catch (error) {
      console.error("Failed to remove artifact(s)", error);
    } finally {
      setRemoving(false);
      onRefresh?.();
    }
  };

  const handleUploadArtifact = async (artifact: File, vanityName: string, type: string) => {
    if (accessToken && caseContext) {
      await uploadArtifact(accessToken, {
        contextId: caseContext.contextId,
        content: await encodeBase64RemovePrefix(artifact),
        artifactType: type,
        artifactName: vanityName,
      });
      onRefresh?.();
    }
  };

  return (
    <Container>
      <SpaceBetween size="m">
        <Table
          columnDefinitions={[
            {
              id: "artifactId",
              header: "Artifact ID",
              cell: (item) => <Link onClick={() => {}}>{item.artifactId || "-"}</Link>,
              // TODO: download artifact document on click
              sortingField: "artifactId",
              isRowHeader: true,
            },
            {
              id: "name",
              header: "Artifact Name",
              cell: (item) => item.name || "-",
              sortingField: "name",
              editConfig: {
                editIconAriaLabel: "editable",
                errorIconAriaLabel: "Name Error",
                editingCell: (item, { currentValue, setValue }) => {
                  return (
                    <Input
                      autoFocus={true}
                      value={currentValue ?? item.name}
                      onChange={(event) => setValue(event.detail.value)}
                    />
                  );
                },
              },
            },
            {
              id: "type",
              header: "Artifact Type",
              cell: (item) => item.type || "-",
              sortingField: "type",
            },
          ]}
          submitEdit={(item, col, newValue) => {
            const updatedItem: CustomArtifact = { ...item, name: newValue as string };
            if (caseContext) {
              const modifiedCaseContext: CaseContext = {
                ...caseContext!,
                artifacts: caseContext?.artifacts.map((a) => (a.artifactId === item.artifactId ? updatedItem : a)),
              };
              updateCaseContext(accessToken!, { caseContext: modifiedCaseContext });
              onRefresh?.();
            }
          }}
          enableKeyboardNavigation
          loading={loading}
          items={items}
          loadingText="Loading resources"
          sortingDisabled
          variant="embedded"
          selectionType="multi"
          trackBy="artifactId"
          selectedItems={selectedItems}
          onSelectionChange={(e) => {
            setSelectedItems(e.detail.selectedItems);
          }}
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No resources</b>
                <Button onClick={() => setShowUploadPanel(true)}>Upload artifact</Button>
              </SpaceBetween>
            </Box>
          }
          header={
            <Header
              variant="h3"
              actions={
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    onClick={() => {
                      setRefreshing(true);
                      onRefresh?.().finally(() => setRefreshing(false));
                    }}
                    iconName="refresh"
                    loading={refreshing}
                    disabled={onRefresh === undefined}
                  ></Button>
                  <Button
                    onClick={handleRemoveArtifact}
                    loading={removing}
                    disabled={selectedItems.length === 0 || !accessToken}
                  >
                    Remove
                  </Button>
                  <Button onClick={() => setShowUploadPanel(!showUploadPanel)}>
                    {showUploadPanel ? "Hide" : "Upload"}
                  </Button>
                </SpaceBetween>
              }
            >
              Custom Artifacts
            </Header>
          }
        />
        {showUploadPanel && (
          <form onSubmit={(e) => e.preventDefault()}>
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button onClick={() => setShowUploadPanel(false)}>Cancel</Button>
                  <Button
                    variant="primary"
                    loading={uploading}
                    onClick={() => {
                      setUploading(true);
                      handleUploadArtifact(artifact[0], artifactName, artifactType).finally(() => {
                        setUploading(false);
                        setShowUploadPanel(false);
                      });
                    }}
                  >
                    Upload
                  </Button>
                </SpaceBetween>
              }
              header={<Header variant="h3">Upload Artifact</Header>}
            >
              <SpaceBetween size={"s"}>
                <FormField label="Artifact" description="Upload artifact from device">
                  <FileUpload
                    onChange={({ detail }) => setArtifact(detail.value)}
                    value={artifact}
                    i18nStrings={{
                      uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                      dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                      removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                      limitShowFewer: "Show fewer files",
                      limitShowMore: "Show more files",
                      errorIconAriaLabel: "Error",
                      warningIconAriaLabel: "Warning",
                    }}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    constraintText="Files under 3MB"
                  />
                </FormField>
                <FormField description="Readable name for your artifact" label="Vanity Name">
                  <Input value={artifactName} onChange={(event) => setArtifactName(event.detail.value)} />
                </FormField>
                <FormField label="Artifact Type">
                  <Select
                    options={[
                      { label: "PDF", value: "PDF" },
                      { label: "CSV", value: "CSV" },
                      { label: "JSON", value: "JSON" },
                    ]}
                    selectedOption={{
                      value: artifactType,
                      label: artifactType,
                    }}
                    onChange={(event) => setArtifactType(event.detail.selectedOption?.value ?? "")}
                  />
                </FormField>
              </SpaceBetween>
            </Form>
          </form>
        )}
      </SpaceBetween>
    </Container>
  );
};
