import { AuthUser, fetchAuthSession, getCurrentUser, signIn, updatePassword } from "aws-amplify/auth";
import axios from "axios";
import { EndpointConfigs } from "../api/endpointConfigs";
import { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
export interface CognitoConfig {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
}

export interface IAttribute {
  Name: string;
  Value: string;
}

export const useCognito = () => {
  const [cognitoConfig, setCognitoConfig] = useState<CognitoConfig | undefined>(undefined);
  const [cognitoLoading, setCognitoLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [authUser, setAuthUser] = useState<AuthUser | undefined>(undefined);

  const fetchCognitoConfig = async () => {
    try {
      const response = await axios.get(EndpointConfigs.authApiUrl());
      const config: CognitoConfig = response.data;
      setCognitoConfig(config);
      Amplify.configure({
        Auth: {
          Cognito: {
            userPoolClientId: config.userPoolWebClientId,
            userPoolId: config.userPoolId,
            loginWith: {
              username: true,
            },
          },
        },
      });
      const session = await fetchAuthSession();
      if (session.tokens !== undefined) {
        setAuthenticated(true);
        setAccessToken(session.tokens.accessToken.toString());
        fetchCognitoUser();
      }
      setCognitoLoading(false);
    } catch (error) {
      console.error("Error fetching Cognito config:", error);
    } finally {
      setCognitoLoading(false);
    }
  };

  const fetchCognitoUser = async () => {
    const user = await getCurrentUser();
    setAuthUser(user);
    return user;
  };

  useEffect(() => {
    fetchCognitoConfig();
  }, []);

  const authenticate = async (username: string, password: string) => {
    const { isSignedIn, nextStep } = await signIn({
      username,
      password,
    });
    setAuthenticated(isSignedIn);
  };

  const changeUserPassword = async (oldPassword: string, newPassword: string) => {
    try {
      await updatePassword({ oldPassword, newPassword });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    authUser,
    cognitoConfig,
    accessToken,
    cognitoLoading,
    authenticated,
    authenticate,
    fetchCognitoUser,
    changeUserPassword,
  };
};
