import { FunctionComponent, useEffect, useState } from "react";
import { FluxTableWidgetProps } from "../../common/standards";
import Table from "@cloudscape-design/components/table";
import Link from "@cloudscape-design/components/link";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import FileUpload from "@cloudscape-design/components/file-upload";
import Input from "@cloudscape-design/components/input";
import Select from "@cloudscape-design/components/select";
import { SourceType } from "../../common/enums";
import { range } from "../../common/helpers";
import { ConfigurationSelector } from "../misc/ConfigurationSelector";
import { removeSource } from "../../api/caseContext";
import { Container } from "@cloudscape-design/components";

export interface SourceTableProps extends FluxTableWidgetProps<SourceTableItem> {
  onSourceUpload: (source: File, vanityName: string, sourceType: SourceType) => Promise<void>;
  onSourceUpdate: (item: SourceTableItem) => Promise<void>;
  onQuery: (sourcePaths: string[], configurationId: string) => Promise<void>;
}

export interface SourceTableItem {
  fullPath: string;
  type: string;
  sourceId: string;
  name: string;
}

export const SourceTable: FunctionComponent<SourceTableProps> = ({
  items,
  onSourceUpload,
  onSourceUpdate,
  onQuery,
  onRefresh,
  accessToken,
}) => {
  const [selectedItems, setSelectedItems] = useState<SourceTableItem[]>([]);
  const [sourceName, setSourceName] = useState<string>("");
  const [sourceType, setSourceType] = useState<SourceType>(SourceType.APPRAISAL);
  const [source, setSource] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [showUploadPanel, setShowUploadPanel] = useState<boolean>(false);

  const [selectedConfigurationId, setSelectedConfigurationId] = useState<string | undefined>(undefined);
  const [querying, setQuerying] = useState<boolean>(false);
  const [removing, setRemoving] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  useEffect(() => {
    setSelectedItems([]);
  }, [items]);

  const handleRemoveSource = async () => {
    setRemoving(true);
    try {
      await Promise.all(
        selectedItems.map((item) =>
          removeSource(accessToken!, {
            contextId: item.fullPath, // Assuming fullPath is the contextId
            sourceS3Path: item.sourceId,
          })
        )
      );
    } catch (error) {
      console.error("Failed to remove source(s)", error);
    } finally {
      setRemoving(false);
    }
  };

  return (
    <Container>
      <SpaceBetween size="m">
        <Table
          columnDefinitions={[
            {
              id: "sourceId",
              header: "Source ID",
              cell: (item) => <Link onClick={() => {}}>{item.sourceId || "-"}</Link>,
              // TODO: download source document on click
              sortingField: "sourceId",
              isRowHeader: true,
            },
            {
              id: "name",
              header: "Source Name",
              cell: (item) => item.name || "-",
              sortingField: "name",
              editConfig: {
                editIconAriaLabel: "editable",
                errorIconAriaLabel: "Name Error",
                editingCell: (item, { currentValue, setValue }) => {
                  return (
                    <Input
                      autoFocus={true}
                      value={currentValue ?? item.name}
                      onChange={(event) => setValue(event.detail.value)}
                    />
                  );
                },
              },
            },
            {
              id: "type",
              header: "Source Type",
              cell: (item) => item.type || "-",
              sortingField: "type",
            },
          ]}
          // footer={}
          submitEdit={(item, _, val) => {
            onSourceUpdate({
              ...item,
              name: val as string,
            });
          }}
          enableKeyboardNavigation
          items={items}
          loadingText="Loading resources"
          sortingDisabled
          variant="embedded"
          selectionType="multi"
          trackBy="sourceId"
          selectedItems={selectedItems}
          onSelectionChange={(e) => {
            setSelectedItems(e.detail.selectedItems);
          }}
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No resources</b>
                <Button onClick={() => setShowUploadPanel(true)}>Upload source document</Button>
              </SpaceBetween>
            </Box>
          }
          header={
            <Header
              variant="h3"
              actions={
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    onClick={() => {
                      setRefreshing(true);
                      onRefresh?.().finally(() => setRefreshing(false));
                    }}
                    iconName="refresh"
                    loading={refreshing}
                    disabled={onRefresh === undefined}
                  ></Button>
                  <ConfigurationSelector
                    onConfigurationSelected={(c) => {
                      console.log(c);
                      setSelectedConfigurationId(c?.configurationId);
                    }}
                    accessToken={accessToken}
                  />
                  <Button
                    variant="primary"
                    onClick={async () => {
                      setQuerying(true);
                      if (selectedConfigurationId) {
                        await onQuery(
                          selectedItems.map((item) => item.fullPath),
                          selectedConfigurationId
                        );
                      }
                      setQuerying(false);
                    }}
                    loading={querying}
                    disabled={!range(1, 5).includes(selectedItems.length) || !selectedConfigurationId}
                  >
                    Query
                  </Button>
                  <Button
                    onClick={handleRemoveSource}
                    loading={removing}
                    disabled={selectedItems.length === 0 || !accessToken}
                  >
                    Remove
                  </Button>
                  <Button onClick={() => setShowUploadPanel(!showUploadPanel)}>
                    {showUploadPanel ? "Hide" : "Upload"}
                  </Button>
                </SpaceBetween>
              }
            >
              Documents
            </Header>
          }
        />
        {showUploadPanel && (
          <form onSubmit={(e) => e.preventDefault()}>
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button onClick={() => setShowUploadPanel(false)}>Cancel</Button>
                  <Button
                    variant="primary"
                    loading={uploading}
                    onClick={() => {
                      setUploading(true);
                      onSourceUpload(source[0], sourceName, sourceType).finally(() => setUploading(false));
                    }}
                  >
                    Upload
                  </Button>
                </SpaceBetween>
              }
              header={<Header variant="h3">Upload Document</Header>}
            >
              <SpaceBetween size={"s"}>
                <FormField label="Source Document" description="Upload document from device">
                  <FileUpload
                    onChange={({ detail }) => setSource(detail.value)}
                    value={source}
                    i18nStrings={{
                      uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                      dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                      removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                      limitShowFewer: "Show fewer files",
                      limitShowMore: "Show more files",
                      errorIconAriaLabel: "Error",
                      warningIconAriaLabel: "Warning",
                    }}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    constraintText="PDF documents under 3MB"
                  />
                </FormField>
                <FormField description="Readable name for your document" label="Vanity Name">
                  <Input value={sourceName} onChange={(event) => setSourceName(event.detail.value)} />
                </FormField>
                <FormField label="Source Type">
                  <Select
                    options={[
                      { label: SourceType.APPRAISAL, value: SourceType.APPRAISAL },
                      { label: SourceType.BORROWER_BACKGROUND, value: SourceType.BORROWER_BACKGROUND },
                      { label: SourceType.TERM_SHEET, value: SourceType.TERM_SHEET },
                      { label: SourceType.RENT_ROLL, value: SourceType.RENT_ROLL },
                      { label: SourceType.OPERATING_STATEMENT, value: SourceType.OPERATING_STATEMENT },
                      {
                        label: SourceType.PERSONAL_FINANCIAL_STATEMENT,
                        value: SourceType.PERSONAL_FINANCIAL_STATEMENT,
                      },
                      { label: SourceType.SPONSOR_BACKGROUND, value: SourceType.SPONSOR_BACKGROUND },
                    ]}
                    selectedOption={{
                      value: sourceType,
                      label: sourceType,
                    }}
                    onChange={(event) => setSourceType(event.detail.selectedOption?.value as SourceType)}
                  />
                </FormField>
              </SpaceBetween>
            </Form>
          </form>
        )}
      </SpaceBetween>
    </Container>
  );
};
