import { FunctionComponent, useState } from "react";
import { Configuration } from "../../common/types/models";
import Table from "@cloudscape-design/components/table";
import Link from "@cloudscape-design/components/link";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import { FluxTableWidgetProps } from "../../common/standards";
import { deleteConfiguration } from "../../api/configurations";

export const ConfigurationTable: FunctionComponent<FluxTableWidgetProps<Configuration>> = ({
  items,
  loading,
  accessToken,
  onRefresh,
  onItemClicked,
}) => {
  const [selectedItems, setSelectedItems] = useState<Configuration[]>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  return (
    <Table
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                iconName="refresh"
                loading={isRefreshing}
                onClick={() => {
                  setIsRefreshing(true);
                  onRefresh().finally(() => {
                    setIsRefreshing(false);
                  });
                }}
              />

              <Button
                disabled={selectedItems.length === 0 || !accessToken}
                onClick={() => {
                  setIsDeleting(true);
                  Promise.all(selectedItems.map((item) => deleteConfiguration(accessToken!, item.configurationId)))
                    .catch((error) => {
                      console.error("Failed to delete configurations:", error);
                    })
                    .finally(() => {
                      setIsDeleting(false);
                      onRefresh();
                    });
                }}
                loading={isDeleting}
              >
                Delete
              </Button>
            </SpaceBetween>
          }
        >
          Saved Configurations
        </Header>
      }
      loading={loading}
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No configurations</b>
            <Button>Create configurations</Button>
          </SpaceBetween>
        </Box>
      }
      selectedItems={selectedItems}
      items={items}
      loadingText="Loading configurations..."
      selectionType="multi"
      trackBy="configurationId"
      onSelectionChange={(e) => {
        setSelectedItems(e.detail.selectedItems);
      }}
      columnDefinitions={[
        {
          id: "configurationId",
          header: "Configuration ID",
          cell: (item) => (
            <Link
              onClick={() => {
                onItemClicked(item);
              }}
            >
              {item.configurationId}
            </Link>
          ),
        },
        {
          id: "name",
          header: "Name",
          cell: (item) => item.name,
        },
        {
          id: "version",
          header: "Version",
          cell: (item) => item.version,
        },
        {
          id: "createdAt",
          header: "Created At",
          cell: (item) => item.createdAt,
        },
        {
          id: "updatedAt",
          header: "Updated At",
          cell: (item) => item.updatedAt,
        },
      ]}
    />
  );
};
